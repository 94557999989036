<template>
    <div class="sf-specification">
        <div class="sf-specification__header">
            <h2
                :id="`${componentId}-title`"
                class="sf-specification__title"
            >
                {{ title }}
            </h2>

            <sf-button
                v-if="moreInfo && moreInfo.title && moreInfo.content"
                :data-appearance="moreInfo.isNearlyClosed ? 'nearly-closed' : 'default'"
                class="dn-button--label sf-specification__button"
                :aria-describedby="`${componentId}-title`"
                @click="modalStore.open({
                    name: 'content',
                    title: moreInfo.title,
                    content: moreInfo.content
                })"
            >
                <slot name="moreInfoLabel">{{ moreInfo.title }}</slot>
            </sf-button>
        </div>

        <div class="sf-specification__description">
            {{ description }}
        </div>
    </div>
</template>

<script>
import SfButton from '~/patterns/atoms/button/button';
import { useModalStore } from '~/store/modal';
let uuid = 0;

export default {
    components: {
        SfButton
    },

    props: {
        title: {
            type: String,
            default: null
        },
        description: {
            type: String,
            default: null
        },
        moreInfo: {
            type: Object,
            default: null
        }
    },

    data() {
        return {
            componentId: null,
            modalStore: useModalStore()
        };
    },

    mounted() {
        this.componentId = `specification-${uuid}`; // Make sure each component has it's own ID to prevent duplicate IDs
        uuid += 1;
    }
};
</script>

<style lang="less" src="./specification.less" />
