<template>
    <div
        v-if="steps && steps.length"
        class="sf-step-list"
    >
        <h2 class="sf-step-list__title">{{ t('title') }}</h2>

        <ul>
            <li
                v-for="(step, index) in steps"
                :key="index"
                class="sf-step-list__item"
            >
                <button
                    type="button"
                    @click="clickStep(step)"
                >
                    <div class="sf-step-list__item__bubble">
                        <span>{{ index + 1 }}</span>
                    </div>

                    <div class="sf-step-list__item__title">
                        {{ step.title }}
                    </div>
                </button>
            </li>
        </ul>
    </div>
</template>

<script setup>
defineProps({
    steps: {
        type: Array,
        default: null
    }
});

const { t } = useI18n({ useScope: 'local' });

const modalStore = useModalStore();
const clickStep = (step) => {
    modalStore.open({
        name: 'content',
        title: step.title,
        content: step.description
    });
};
</script>

<style lang="less" src="./step-list.less"></style>

<i18n>
{
    "nl": {
        "title": "Het stappenplan"
    },
    "en": {
        "title": "Steps to take"
    }
}
</i18n>
