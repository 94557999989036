<template>
    <div class="sf-media">
        <div
            v-if="!!$slots.image"
            class="sf-media__image"
        >
            <slot name="image" />
        </div>
        <div
            v-if="!!$slots.body"
            class="sf-media__body"
        >
            <slot name="body" />
        </div>
    </div>
</template>

<style lang="less" src="./media.less"></style>
