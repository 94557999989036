<template>
    <SfBasicTemplate v-if="entry">
        <template #default>
            <SfHero
                class="sf-hero--grant"
                :title="entry.title"
                :images="entry.heroImages"
            >
                <template #stickers>
                    <SfStickerNearlyClosed
                        v-if="entry.isNearlyClosed"
                    />
                    <SfStickerNew
                        v-if="entry.isNew"
                    />
                </template>
            </SfHero>

            <SfLeadTextSection
                v-if="entry.shortDescription"
                :block="{
                    wysiwyg: entry.shortDescription
                }"
            />

            <SfSection v-if="specificationData">
                <SfSpecsList :specs="specificationData" />
            </SfSection>

            <SfAccordionSection
                v-if="entry.accordion && entry.accordion.length"
                :block="{
                    title: t('title.important-info'),
                    accordion: entry.accordion
                }"
            />

            <SfSection :layout="globalVideo ? 'video' : 'stappenplan'">
                <template #default>
                    <SfStepList
                        :steps="entry.steps"
                    />
                </template>

                <template #aside>
                    <SfDiscoverCard
                        v-if="globalVideo"
                        card-type="video"
                        orientation="portrait"
                        :image="globalVideo.featuredImage && globalVideo.featuredImage.length > 0 ? globalVideo.featuredImage[0] : undefined"
                        :title="globalVideo.title"
                        :vimeo-url="globalVideo.vimeoUrl"
                        :accessible-vimeo-url="globalVideo.accessibleVimeoUrl"
                    />

                    <SfDiscoverCard
                        v-else-if="tips && tips.length"
                        card-type="tips"
                        orientation="portrait"
                        title="Hoe kom je tot een overtuigende aanvraag?"
                        :tips="tips.map(tip => tip.description)"
                    />
                </template>
            </SfSection>

            <SfSection>
                <SfPanel>
                    <p class="lead">
                        <template v-if="entry.type === 'open-call'">
                            {{ t('cta.description.open-call') }}
                        </template>
                        <template v-else>
                            {{ t('cta.description.grant') }}
                        </template>
                    </p>

                    <SfButton
                        :href="entry.applicationUrl"
                        element="a"
                        data-ga-category="start-aanvraag"
                        :data-ga-action="entry.applicationUrl"
                        :data-ga-label="$route.path"
                        class="dn-button--large"
                        style="margin-top: .5em;"
                        target="_blank"
                    >
                        <template #pre><sf-icon icon="arrow-right" /></template>
                        <template #default>{{ t('cta.label') }}</template>
                    </SfButton>

                    <SfMedia>
                        <template #body>
                            <span class="sf-media__title">{{ entry.contactTitle || t('contact.title') }}</span>
                            <div v-if="entry.contactInformation" v-html="entry.contactInformation" />  <!-- eslint-disable-line -->
                            <div v-if="contactPerson">
                                <i18n-t
                                    keypath="contact.text"
                                    tag="p"
                                >
                                    <template #faq>
                                        <nuxt-link :to="$i18n.locale === 'nl' ? '/veelgestelde-vragen' : '/en/faq'">{{ t('contact.faq') }}</nuxt-link>
                                    </template>
                                    <template #email>
                                        <a :href="`mailto:${ contactPerson.email}`">{{ contactPerson.title }}</a>
                                    </template>
                                    <template #phone>
                                        <a :href="`tel:${ contactPerson.phone}`">{{ contactPerson.phone }}</a>
                                    </template>
                                </i18n-t>
                            </div>
                        </template>
                    </SfMedia>
                </SfPanel>
            </SfSection>

            <!-- <SfSection>
                <ul class="list--clean">
                    <li>
                        <SfButton
                            element="nuxt-link"
                            :to="localePath('grants')"
                            class="dn-button--link"
                        >
                            <template #default>{{ t('link.back-to-index') }}</template>
                            <template #after>
                                <sf-icon icon="arrow-right" />
                            </template>
                        </SfButton>
                    </li>
                </ul>
            </SfSection> -->
        </template>

        <template
            v-if="entry.tags && entry.tags.length > 0"
            v-slot:related
        >
            <SfRelatedItems :entry="entry" />
        </template>
    </SfBasicTemplate>
</template>

<script setup>
import GrantQuery from '~/graphql/queries/grants/getGrant.graphql';

import SfBasicTemplate from '~/patterns/templates/basic.vue';
import SfRelatedItems from '~/patterns/organisms/related-items/related-items';
import SfHero from '~/patterns/organisms/hero/hero';
import SfSection from '~/patterns/organisms/section/section';
import SfLeadTextSection from '~/patterns/organisms/content-blocks/blocks/lead-text';
import SfSpecsList from '~/patterns/molecules/specs-list/specs-list.vue';
import SfMedia from '~/patterns/molecules/media/media.vue';
import SfAccordionSection from '~/patterns/organisms/content-blocks/blocks/accordion';
import SfStepList from '~/patterns/molecules/step-list/step-list';
import SfPanel from '~/patterns/molecules/panel/panel';
import SfDiscoverCard from '~/patterns/molecules/discover-card/discover-card.vue';
import SfButton from '~/patterns/atoms/button/button';
import SfIcon from '~/patterns/atoms/icon/icon';
import SfStickerNew from '~/patterns/atoms/sticker/presets/new.vue';
import SfStickerNearlyClosed from '~/patterns/atoms/sticker/presets/nearly-closed.vue';

defineI18nRoute({
    paths: {
        nl: '/subsidies/[slug]',
        en: '/grants/[slug]'
    }
});

const { t } = useI18n({ useScope: 'local' });

const { variables } = useDefaultVariables();

const response = await useAsyncQuery({
    query: GrantQuery,
    variables
});

const { craftEntry: entry } = useEntryPage(response);

const globalVideo = computed(() => entry.value.globalVideo?.[0] ?? null);
const tips = computed(() => entry.value?.globalTips?.[0]?.tips || []);
const specificationData = computed(() => formatSpecificationData(entry.value));
const contactPerson = computed(() => entry.value?.contactPerson?.[0] ?? null);

useCollapse();
useNavigationVisible();
</script>

<i18n lang="json">
{
    "nl": {
        "title.important-info": "Belangrijke info",
        "cta.description.grant": "Wil je subsidie aanvragen bij deze regeling?",
        "cta.description.open-call": "Wil je subsidie aanvragen bij deze open oproep?",
        "cta.label": "Start je aanvraag",
        "contact.faq": "veelgestelde vragen",
        "contact.title": "Heb je vragen?",
        "contact.text": "Is er iets niet duidelijk? Stel je vraag dan per e-mail aan onze collega’s van de {email}. Je kan ook bellen met {phone}. Heb je een meer algemene vraag, bekijk dan eerst de {faq}.",
        "link.back-to-index": "Bekijk andere subsidies"
    },
    "en": {
        "title.important-info": "Important information",
        "cta.description.grant": "Do you want to apply for a subsidy under this grant scheme?",
        "cta.description.open-call": "Do you want to apply for a subsidy under this open call?",
        "cta.label": "Start your application",
        "contact.faq": "frequently asked questions",
        "contact.title": "Do you have any questions?",
        "contact.text": "If some of the information is not clear, email your question to our colleagues of the {email}. You can also call on {phone}. If you have a more general question, have a look at our {faq} first.",
        "link.back-to-index": "View other grants"
    }
}
</i18n>
